import React, { useState, useEffect } from "react";
import logoGif from "../../assets/CSDLogo.gif"; // Import your GIF file

const LogoLoader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  const loaderStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#FFF8F0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
  };
  const smallerScreens = `@media (max-width: 1080px) {
    .logo {
      width: '50%',
    }
  }`;

  const gifStyle = {
    width: "30%",
    height: "auto",
  };

  const footerStyle = {
    position: "fixed",
    bottom: 0,
    margin: "20px",
    fontSize: "14px",
    color: "#555",
  };

  if (!isLoading) {
    return null;
  }

  return (
    <div
      style={loaderStyle}
      className={`video-loader ${isLoading ? "show" : "hide"}`}
    >
      <style>{smallerScreens}</style>
      <img src={logoGif} alt="Loading..." style={gifStyle} className="logo" />
      <div style={footerStyle}>Made in INDIA</div>
    </div>
  );
};

export default LogoLoader;
