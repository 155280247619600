import React from "react";
import { GlobalData } from "../../assets/data/GlobalData";

const Disclaimer = () => {
  return (
    <section className="px-5 my-10">
      <div className="max-w-4xl mx-auto bg-white">
        <h1 className="text-center text-2xl py-10 font-bold">
          {" "}
          Cancellation & Refund Policy{" "}
        </h1>
        Last updated: 2024-06-10
        <br />
        <br />
        <a className="text-lg font-medium ">
          {GlobalData.company.companyName} believes in helping its customers as
          far as possible, and has therefore a liberal cancellation policy.
          Under this policy:
        </a>
        <br />
        <li className="py-2">
          Cancellations will be considered only if the request is made
          immediately after placing the order. However, the cancellation request
          may not be entertained if the orders have been communicated to the
          vendors/merchants and they have initiated the process of shipping
          them.
        </li>
        <li className="py-2">
          {GlobalData.company.companyName} does not accept cancellation and
          Refund.
        </li>
      </div>
    </section>
  );
};

export default Disclaimer;
