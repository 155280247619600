// SYSTEM_APIS
const SINGUP_API = "https://ragsee.com/apis/register/signup.php";
const SINGIN_API = "https://ragsee.com/apis/register/signin.php";
const FORGOT_PASSWORD = "https://ragsee.com/apis/forgetPassword.php";
const CRUD_API_EDITPROFILE = "https://ragsee.com/apis/CRUD.php";

//Store Fetch
const Fetch_product_catalogue =
  "https://ragsee.com/apis/Store/Fetch_product_catalogue.php";
const Fetch_product_sub_catalogue =
  "https://ragsee.com/apis/Store/Fetch_product_sub_catalogue.php";
const Fetch_product_products =
  "https://ragsee.com/apis/Store/Fetch_product_products.php";
const Fetch_productDetails =
  "https://ragsee.com/apis/Store/Fetch_productDetails.php";
const Fetch_newarrivals = "https://ragsee.com/apis/Store/Fetch_newArrivals.php";

//Orders
const Fetch_Orders_History = "https://ragsee.com/apis/Store/Fetch_orders.php";

//Razorpay
const Payment_Gateway_Create_order =
  "https://ragsee.com/apis/PaymentGateway/create-order.php";
const Payment_Gateway_Save_Order =
  "https://ragsee.com/apis/PaymentGateway/save-order.php";
const Payment_Gateway_Save_payment =
  "https://ragsee.com/apis/PaymentGateway/save-payment.php";

const USER_DELETE = "https://ragsee.com/apis/users/deleteUser.php";
const USER_CURRENCY = "https://ragsee.com/apis/USER_CURRENCY.php";
const CRUDUsers = "https://ragsee.com/apis/users/CRUDUsers.php";

export {
  // SYSTEM_APIS
  SINGUP_API,
  SINGIN_API,
  FORGOT_PASSWORD,
  CRUD_API_EDITPROFILE,

  //Store Fetch
  Fetch_product_catalogue,
  Fetch_product_sub_catalogue,
  Fetch_product_products,
  Fetch_productDetails,
  Fetch_newarrivals,

  //Orders
  Fetch_Orders_History,

  //Razorpay
  Payment_Gateway_Create_order,
  Payment_Gateway_Save_Order,
  Payment_Gateway_Save_payment,

  // Admin Tools
  USER_CURRENCY,
  USER_DELETE,
  CRUDUsers,
};
