import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Fetch_product_products } from "../../../hooks/APIHooks";
import { BsBag } from "react-icons/bs";

const ProductList = () => {
  const { subCatalogueId } = useParams();
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await fetch(
          `${Fetch_product_products}?sub_catalogue_id=${subCatalogueId}`
        );
        const data = await response.json();
        if (data.success) {
          setProducts(data.data);
        } else {
          throw new Error(data.error || "Failed to fetch products");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [subCatalogueId]);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);

  const handleProductClick = (productId) => {
    navigate(`/Store/sub-catalogue/products/product-details/${productId}`);
  };

  const handleAddToCart = (product) => {
    const existingProduct = cart.find(
      (item) => item.product_id === product.product_id
    );

    let updatedCart;
    if (existingProduct) {
      updatedCart = cart.map((item) =>
        item.product_id === product.product_id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    } else {
      updatedCart = [...cart, { ...product, quantity: 1 }];
    }

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    alert(`${product.product_name} added to the cart!`);
  };

  // Helper function to render stars based on rating
  const renderStars = (rating) => {
    const maxRating = 5; // Set a max rating if needed
    return Array.from({ length: Math.min(rating, maxRating) }, (_, index) => (
      <span key={index} className="text-yellow-500 text-lg">
        &#9733;
      </span>
    ));
  };

  return (
    <div className="mx-auto my-8 px-4">
      <h1 className="text-xl font-bold mb-4 text-center sm:text-2xl">
        Products for List ID: {subCatalogueId}
      </h1>

      {loading ? (
        <div className="text-center text-lg">Loading...</div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : products.length === 0 ? (
        <div className="text-center text-gray-500">No products found.</div>
      ) : (
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-4">
          {products.map((product) => (
            <div
              key={product.product_id}
              className="relative flex flex-col items-center text-center cursor-pointer p-2 border rounded-lg"
              onClick={() => handleProductClick(product.product_id)}
            >
              <div className="relative w-full h-42 sm:h-58 overflow-hidden rounded-lg">
                <img
                  src={product.product_image || "default-image.jpg"}
                  alt={product.product_name}
                  className="object-cover object-top w-full h-full"
                />
                <button
                  className="absolute top-2 right-2 bg-black rounded-full text-white text-xs p-2 transition-transform hover:scale-105"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(product);
                  }}
                >
                  <BsBag size={15} />
                </button>
              </div>
              <div className="p-2">
                <div className="flex items-center justify-between">
                  <p
                    className="font-medium text-sm sm:text-base line-clamp-2 overflow-hidden text-ellipsis"
                    title={product.product_name || "Unknown Product"}
                  >
                    {product.product_name || "Unknown Product"}
                  </p>
                </div>
                <div className="flex items-center justify-center mb-2 space-x-1">
                  {renderStars(product.product_rating)}
                </div>
                <p className="text-lg sm:text-xl font-bold text-gray-900">
                  ₹{product.price || "N/A"}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductList;
