import React, { useState, useEffect } from "react"; 
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider"; 
import { GoPerson } from "react-icons/go"; 
import { IoBagHandleOutline } from "react-icons/io5"; 
import { IoIosLogOut } from "react-icons/io"; 
import Cat_Menu from "./Cat_Menu"; 
import { BsBag } from "react-icons/bs"; 
import { GoQuestion } from "react-icons/go"; 
import { IoSearchOutline } from "react-icons/io5"; 
import { GlobalData } from "../../assets/data/GlobalData"; 
import { FiMenu } from "react-icons/fi"; 
import { TbHelpTriangle } from "react-icons/tb"; 

import Notification from '../Tools/Notification'

const Navbar = () => {
  const location = useLocation(); 
  const { user, signOut } = useAuth(); 
  const navigate = useNavigate(); 
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false); 
  const [cartItemCount, setCartItemCount] = useState(0);

  const handleLogout = () => { 
    signOut(); 
    navigate("/signin"); 
  };

  // Toggle dropdown visibility when clicking on the profile icon
  const toggleProfileDropdown = () => { 
    setIsProfileDropdownOpen(!isProfileDropdownOpen); 
  };

  useEffect(() => { 
    const cart = JSON.parse(localStorage.getItem("cart")) || []; 
    const totalItems = cart.reduce((total, item) => total + item.quantity, 0); 
    setCartItemCount(totalItems); 
  }, []);

  return (
    <div className="sticky w-full z-[99] top-0 bg-white shadow-md">
      <Notification />
      <div className="flex items-center justify-between p-2 border-b border-gray-200">
        {/* Left: Search Icon */}
        <div className="flex items-center space-x-2 text-black">
          <Link to="/Store">
            <FiMenu size={30} className="hover-yellow" />
          </Link>
          <IoSearchOutline size={30} className="hover-yellow" />
          <TbHelpTriangle size={30} className="text-white" />
        </div>

        {/* Center: Logo */}
        <div className="flex-1 text-center">
          <Link to="/" className="text-[25px] font-bold text-black">
            {GlobalData.company.companyName}
          </Link>
        </div>

        {/* Right: User Profile, Cart, Help */}
        <div className="flex items-center space-x-2 text-black">
          <div
            className="relative cursor-pointer"
            onClick={toggleProfileDropdown}
          >
            <GoPerson size={25} />
            {isProfileDropdownOpen && (
              <div className="absolute right-0 bg-white border border-gray-300 shadow-lg rounded-lg w-60 p-4 z-50 max-h-[calc(100vh-80px)] overflow-auto">
                <div className="text-center mb-3">
                  <p className="font-bold text-md">
                    Hello, {user?.name ? user.name : "Guest"}
                  </p>
                  <p className="text-xs text-gray-500">
                    {user
                      ? "Find all your Ragsee details here"
                      : "To access your Ragsee account"}
                  </p>
                </div>

                {user ? (
                  <div>
                    <Link
                      to="/user/dashboard"
                      className="flex items-center space-x-2 py-2 text-sm text-black hover:bg-gray-100 rounded-md mb-2 transition duration-300"
                    >
                      <GoPerson size={20} />
                      <span>Dashboard</span>
                    </Link>
                    <Link
                      to="/user/orders"
                      className="flex items-center space-x-2 py-2 text-sm text-black hover:bg-gray-100 rounded-md mb-2 transition duration-300"
                    >
                      <IoBagHandleOutline size={20} className="hover-yellow" />
                      <span>My Orders</span>
                    </Link>
                  </div>
                ) : (
                  <Link
                    to="/signin"
                    className="flex items-center space-x-2 py-2 text-sm text-black hover:bg-yellow-100 rounded-md mb-2 transition duration-300"
                  >
                    <IoBagHandleOutline size={20} className="hover-yellow" />
                    <span>Sign Up/Sign In</span>
                  </Link>
                )}

                {user && (
                  <button
                    onClick={handleLogout}
                    className="flex justify-center items-center space-x-2 w-full text-white py-2 rounded-md bg-red-600 hover:bg-red-700 transition duration-300"
                  >
                    <IoIosLogOut size={20} className="hover-yellow" />
                    <span>Logout</span>
                  </button>
                )}
              </div>
            )}
          </div>

          <Link to="/Store/cart" className="relative">
            <BsBag size={25} className="hover-yellow" />
            {cartItemCount > 0 && (
              <div className="absolute z-50 -top-2 -right-2 flex items-center justify-center rounded-full bg-red-500 text-white text-xs w-5 h-5">
                {cartItemCount}
              </div>
            )}
          </Link>

          <Link to="/contactus">
            <GoQuestion size={25} className="hover-yellow" />
          </Link>
        </div>
      </div>

      <Cat_Menu />
    </div>
  );
};

export default Navbar;
