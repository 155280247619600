import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fetch_newarrivals } from "../../../hooks/APIHooks";
import { BsBag } from "react-icons/bs";

const NewArrivals = () => {
  const [newArrivals, setNewArrivals] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewArrivals = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await fetch(Fetch_newarrivals);
        const data = await response.json();
        if (data.status === "success") {
          setNewArrivals(data.data);
        } else {
          throw new Error(data.message || "Failed to fetch new arrivals");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNewArrivals();
  }, []);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);

  const handleAddToCart = (product) => {
    const existingProduct = cart.find(
      (item) => item.product_id === product.product_id
    );

    let updatedCart;
    if (existingProduct) {
      updatedCart = cart.map((item) =>
        item.product_id === product.product_id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
    } else {
      updatedCart = [...cart, { ...product, quantity: 1 }];
    }

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    alert(`${product.product_name} added to the cart!`);
    navigate("/Store/cart");
  };

  return (
    <div className="mx-auto my-8 px-4">
      <h1 className="text-xl font-bold mb-4 text-center sm:text-2xl">
        New Arrivals
      </h1>

      {loading ? (
        <div className="text-center text-lg">Loading...</div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : newArrivals.length === 0 ? (
        <div className="text-center text-gray-500">
          No new arrivals available.
        </div>
      ) : (
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-4">
          {newArrivals.map((item) => (
            <div
              key={item.product_id}
              className="relative flex flex-col items-center text-center cursor-pointer p-2 border rounded-lg"
              onClick={() =>
                navigate(
                  `/Store/sub-catalogue/products/product-details/${item.product_id}`
                )
              }
            >
              <div className="relative w-full h-42 sm:h-58 overflow-hidden rounded-lg">
                <img
                  src={item.product_image || "default-image.jpg"}
                  alt={item.product_name}
                  className="object-cover object-top w-full h-full"
                />
                <button
                  className="absolute top-2 right-2 bg-black rounded-full text-white text-xs p-2 transition-transform hover:scale-105"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(item);
                  }}
                >
                  <BsBag size={15} />
                </button>
              </div>
              <div className="mt-2">
                <p
                  className="font-medium text-sm sm:text-base line-clamp-2 overflow-hidden text-ellipsis"
                  title={item.product_name || "Unknown Product"}
                >
                  {item.product_name || "Unknown Product"}
                </p>

                <p className="text-lg sm:text-xl font-bold text-gray-900">
                  ₹{item.price || "N/A"}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NewArrivals;
