import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { GlobalData } from "../../assets/data/GlobalData";
import ImageSlider from "../../components/Sliders/ImageSlider";
import {
  home_cover_1,
  home_cover_2,
  home_cover_3,
} from "../../assets/data/Imagedata";
import { Fetch_product_catalogue } from "../../hooks/APIHooks";
import LogoLoader from "../../components/LogoLoader/LogoLoader";
import { FaShippingFast, FaLock, FaBoxes } from "react-icons/fa";
import NewArrivals from "../Store/Products/NewArrivals";

function Home() {
  const [catalogueData, setCatalogueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    fetch(Fetch_product_catalogue)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setCatalogueData(data.data);
        } else {
          throw new Error(data.error || "An error occurred");
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => {
        // Add delay before hiding the loader
        setTimeout(() => setLoading(false), 1000); // 2-second delay
      });// Set loading to false once data is fetched
  }, []);

  return (
    <section className="min-w-full">
      <ImageSlider />

      {/*Company Ensure*/}
      <section className="my-5">
        <div className="grid grid-cols-3 text-center">
          <div className="flex items-center space-x-2 mx-auto">
            <FaLock className="text-[25px]" />
            <div className="text-left leading-tight">
              <p className="sm:text-[16px] text-[12px]">Secure & safe</p>
              <p className="sm:text-[12px] text-[10px]">Safe delivery</p>
            </div>
          </div>

          <div className="flex items-center space-x-2 mx-auto">
            <FaShippingFast className="text-[25px]" />
            <div className="text-left leading-tight">
              <p className="sm:text-[16px] text-[12px]">Fast delivery</p>
              <p className="sm:text-[12px] text-[10px]">5000+ dtyles</p>
            </div>
          </div>

          <div className="flex items-center space-x-2 mx-auto">
            <FaBoxes className="text-[25px]" />
            <div className="text-left leading-tight">
              <p className="sm:text-[16px] text-[12px]">Free shipping</p>
              <p className="sm:text-[12px] text-[10px]">For orders 990+</p>
            </div>
          </div>
        </div>
      </section>

      {/*New Arrivals*/}
      <NewArrivals />

      {/*Cover 1*/}
      <section>
        <div className="flex items-center">
          <section
            className="object-cover rounded-t-3xl bg-cover bg-center flex h-[20vh] md:h-[30vh] lg:h-[70vh] w-full"
            style={{ backgroundImage: `url("${home_cover_1}")` }}
          >
            <div className="container mx-auto flex items-center h-full">
              <div className="text-left text-white w-1/2 p-2 leading-tight">
                <p className="sm:text-[46px] text-[22px] font-bold">Shop Now</p>
                <p className="sm:text-[22px] text-[14px] ">Description</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/*Special Offers*/}
      <section className="">
        <h2 className=" shine-gold text-primary font-black text-[36px] italic text-center">
          SPECIAL OFFER
        </h2>
      </section>

      {/*Cover 2*/}
      <section>
        <div className="flex items-center">
          <section
            className="object-cover rounded-b-3xl bg-cover bg-center flex h-[20vh] md:h-[30vh] lg:h-[70vh] w-full"
            style={{ backgroundImage: `url("${home_cover_2}")` }}
          >
            <div className="container mx-auto flex items-center h-full">
              <div className="text-left text-white w-1/2 p-2 leading-tight">
                <p className="sm:text-[46px] text-[22px] font-bold">Shop Now</p>
                <p className="sm:text-[22px] text-[14px] ">Description</p>
              </div>
            </div>
          </section>
        </div>
      </section>

      {/*Store*/}
      <section className="w-full overflow-hidden">
        {loading ? (
          // Show LogoLoader until loading is true
          <div className="flex justify-center items-center h-[100vh]">
            <LogoLoader />
          </div>
        ) : error ? (
          // Show error message if data fetch fails
          <div className="text-center text-red-500">{error}</div>
        ) : (
          // Show catalogue data once loading is completed
          <div>
            <h2 className="shine-gold text-primary font-black text-[36px] italic text-center">
              Categories
            </h2>
            <p className="text-center leading-tight text-[12px] px-2 sm:text-[14px]">
              Explore the wide range of categories in our store.South India's
              most reliable Ecommerce
            </p>
            <div className="grid grid-cols-3 gap-2 p-2">
              {catalogueData.map((item) => (
                <Link
                  key={item.catalogue_id}
                  to={`/Store/sub-catalogue/${item.catalogue_id}`}
                  className="relative rounded-lg shadow-md h-[23vh] sm:h-[40vh]"
                  style={{
                    backgroundImage: `url(${
                      item.catalogue_image || "default-image.jpg"
                    })`, // Fallback image
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                    <h2 className="text-[14px] font-bold text-white">
                      {item.catalogue_name || "Unknown Catalogue"}
                    </h2>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </section>

      {/*Cover 3*/}
      <section>
        <div className="flex items-center">
          <div
            className="object-cover bg-cover bg-center flex h-[30vh] md:h-[30vh] lg:h-[70vh] w-full relative"
            style={{ backgroundImage: `url("${home_cover_3}")` }}
          >
            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 text-center text-white p-2 leading-tight">
              <p className="sm:text-[46px] text-[22px] font-bold">Shop Now</p>
              <p className="sm:text-[22px] text-[14px]">Description</p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Home;
