import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Fetch_product_catalogue } from "../../hooks/APIHooks";

function Cat_Menu() {
  const [catalogueData, setCatalogueData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    fetch(Fetch_product_catalogue)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setCatalogueData(data.data);
        } else {
          throw new Error(data.error || "An error occurred");
        }
      })
      .catch((err) => setError(err.message))
      .finally(() => setLoading(false)); // Set loading to false once data is fetched
  }, []);

  return (
    <div className="relative">
      {/* Loading or error state */}
      {loading ? (
        <div className="flex justify-cener items-center">
          <div>Loading...</div>
        </div>
      ) : error ? (
        <div className="text-center text-red-500">{error}</div>
      ) : (
        <div className="overflow-x-auto scrollbar-hide"
          style={{ scrollbarWidth: "none" }}>
          <div className="flex justify-between py-2 text-center sm:py-4 border-b">
            {/* Dynamically generate catalogue links */}
            {catalogueData.map((item) => (
              <Link
                key={item.catalogue_id}
                to={`/Store/sub-catalogue/${item.catalogue_id}`}
                className="whitespace-nowrap px-2 font-semibold justify-between text-black hover:text-primary text-[12px]"
              >
                {item.catalogue_name}
              </Link>
              
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Cat_Menu;
