import React from "react";
import { useLocation } from "react-router-dom";

function OrderDetails() {
  const location = useLocation();
  const { order } = location.state || {}; // Access order details passed via navigate

  if (!order) {
    return <p className="text-center text-red-500">No order details found.</p>;
  }

  return (
    <section className="mt-8 px-5">
      <h3 className="font-bold text-xl mb-4">Order Details</h3>
      <div className="bg-white rounded-2xl p-4 shadow-lg">
        <div className="mb-4">
          <p><strong>Order ID:</strong> {order.order_id}</p>
          <p><strong>Payment Status:</strong> {order.payment_status}</p>
          <p><strong>Delivery Status:</strong> {order.delivery_status}</p>
          <p><strong>Total Price:</strong> ₹{order.total_amount}</p>
          <p><strong>Order Created:</strong> {order.created_at}</p>
        </div>
        <div>
          <h4 className="font-bold mb-2">Cart Items</h4>
          <ul>
            {JSON.parse(order.cart_items).map((item, index) => (
              <li key={index} className="border-b py-2">
                <p><strong>Product:</strong> {item.product_name}</p>
                <p><strong>Variant:</strong> {item.variant}</p>
                <p><strong>Quantity:</strong> {item.quantity}</p>
                <p><strong>Price:</strong> ₹{item.price}</p>
                <img
                  src={item.product_image}
                  alt={item.product_name}
                  className="w-[200px] h-[150px] object-cover rounded-lg shadow-sm"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default OrderDetails;
