import AdvertisingCoverHomeImage from "../CoverImages/AdvertisingCoverHomeImage.webp";

const GlobalData = {
  company: {
    companyName: "Ragsee",
    companyTagLine: "India's Fastest Ecommerce",
    companyWebsite:"www.ragsee.com",
    companyPhone: "+918143407758",
    companyEmail: "support@ragsee.com",
    companyAddress: "Visakhapatnam, Andhra Pradesh 530017",
    companyWhatsapp: "https://api.whatsapp.com/send?phone=918143407758",
  },

  WebDevelopmentServices: [
    {
      image: AdvertisingCoverHomeImage,
      title: "Business Websites",
      subtitle:
        "Catalyzing Business Success: Our business website solutions are designed to drive growth, enhance visibility, and boost profitability in the digital landscape.",
      slug: "/build-business-website",
      buttonText: "Get Quotation",
    },

    {
      image: AdvertisingCoverHomeImage,
      title: "E-commerce",
      subtitle:
        " Crafting E-Commerce Excellence: Elevate your online retail with our bespoke e-commerce solutions. Boost sales, engage customers, and thrive online.",
      slug: "/build-ecommerce-website",
      buttonText: "Get Quotation",
    },
    {
      image: AdvertisingCoverHomeImage,
      title: "Web Revamp",
      subtitle:
        "Revamp Your Online Presence: Transform and modernize your existing website with our web revamp services. Stay ahead and impress your audience.",
      slug: "/customize-your-website",
      buttonText: "Get Quotation",
    },
    {
      image: AdvertisingCoverHomeImage,
      title: "CRM/ERP Panels",
      subtitle:
        "Efficient CRM/ERM Solutions: Enhance business operations with our CRM/ERM panels. Streamline processes, manage data, and boost productivity effectively.",
      slug: "/control-center-for-business",
      buttonText: "Get Quotation",
    },
  ],
};

export { GlobalData };
