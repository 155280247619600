import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useAuth } from "../../hooks/GlobalProvider";
import { GlobalData } from "../../assets/data/GlobalData";

const ReportABug = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.emailaddress || "",
    phoneNumber: user?.phonenumber || "",
    issueType: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const discordWebhookUrl =
      "https://discord.com/api/webhooks/1188142399332032512/t_u3FNseO4CzVYp-yn7eUWNdF8wweauOpcjlqeA3xkh6XWQrVGTulVlkTXcQnYQLr1GE";

    const message = {
      content: `**Message From ${GlobalData?.company?.companyWebsite || "Unknown Source"}**\n\n**Name:** ${
        formData.name || "No Name Provided"
      }\n**Email:** ${
        formData.email || "No Email Provided"
      }\n**Phone Number:** ${
        formData.phoneNumber || "No Phone Number Provided"
      }\n**Issue Type:** ${
        formData.issueType || "Not Specified"
      }\n**Message:** ${formData.message || "No Message Provided"}`,
    };

    try {
      const response = await fetch(discordWebhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
      });

      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({
          name: user?.name || "",
          email: user?.emailaddress || "",
          phoneNumber: user?.phonenumber || "",
          issueType: "",
          message: "",
        });
      } else {
        alert("Error submitting the form. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Report A Bug - Cyber Space Digital</title>
        <meta
          name="description"
          content="Web Development and Digital Marketing Company. We are here to build your business online. One stop for all your digital needs."
        />
        <link rel="canonical" href="/contactus" />
      </Helmet>

      <section className="bg-gray-200 py-20">
        <div className="relative flex items-top justify-center sm:items-center sm:pt-0">
          <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="p-6 mr-2 sm:rounded-lg">
                  <h1 className="text-center sm:text-left text-4xl sm:text-5xl text-black font-extrabold tracking-tight">
                    Report a Bug
                  </h1>
                  <p className="text-center sm:text-left text-normal text-lg sm:text-2xl font-medium text-black mt-2">
                    Start your issue.
                  </p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-8 mx-5 my-5">
                  <div>
                    <label
                      htmlFor="issueType"
                      className="text-black block mb-2 text-sm font-medium"
                    >
                      Issue Type:
                      <select
                        name="issueType"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
                        value={formData.issueType}
                        onChange={handleChange}
                        required
                      >
                        <option value="" disabled>
                          Select an Issue
                        </option>
                        <option value="UI/UX Error">UI/UX Error</option>
                        <option value="Payment Error">Payment Error</option>
                        <option value="Performance Issue">Performance Issue</option>
                        <option value="Crash/Error">Crash/Error</option>
                        <option value="Other">Other</option>
                        <option value="Looking for an Ecommerce Website">
                          Looking for an Ecommerce Website
                        </option>
                      </select>
                    </label>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="message"
                      className="block mb-2 text-sm font-medium"
                    >
                      Please State Your Issue:
                      <textarea
                        name="message"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Leave a comment..."
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="text-white bg-black block w-full rounded-3xl border border-black px-12 py-3 text-sm font-medium hover:text-black hover:bg-white sm:w-auto"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReportABug;
