import React, { useState, useEffect } from "react"; 
import { Link } from "react-router-dom";
import { Fetch_product_catalogue } from "../../hooks/APIHooks"; 
import LogoLoader from "../../components/LogoLoader/LogoLoader";

const Store = () => { 
  const [catalogueData, setCatalogueData] = useState([]); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Fetch_product_catalogue);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        if (data.success) {
          setCatalogueData(data.data);
          setLoading(false);  // Set loading to false only after the data is fetched
        } else {
          throw new Error(data.error || "An error occurred");
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);  // Stop loader even if there is an error
      }
    };

    fetchData();
  }, []);

  return (
    <section className="w-full overflow-hidden">
      {loading ? (
        <div className="flex justify-center items-center h-[100vh]">
          <LogoLoader />
        </div>
      ) : error ? (
        // Show error message if data fetch fails
        <div className="text-center text-red-500">{error}</div>
      ) : (
        // Show catalogue data once loading is completed
        <div className="grid grid-cols-3 lg:grid-cols-5 gap-4 p-4">
          {catalogueData.map((item) => (
            <Link
              key={item.catalogue_id}
              to={`/Store/sub-catalogue/${item.catalogue_id}`}
              className="relative rounded-lg shadow-md h-[23vh] sm:h-[50vh]"
              style={{
                backgroundImage: `url(${item.catalogue_image || "default-image.jpg"})`, // Fallback image
                backgroundSize: "cover",
                backgroundPosition: "Top",
              }}
            >
              <div className="absolute bottom-0 w-full text-center bg-opacity-50 bg-black p-2">
                <h2 className="text-[14px] font-bold text-white">
                  {item.catalogue_name || "Unknown Catalogue"}
                </h2>
              </div>
            </Link>
          ))}
        </div>
      )}
    </section>
  );
};

export default Store;
