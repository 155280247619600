import React from "react";
import { Link } from "react-router-dom";
import footer_csd from "./csdv2Logofooter.svg";
import { GlobalData } from "../../assets/data/GlobalData";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <main className="bg-black">
      {/* Footer */}
      <section className="bg-black pb-5">
        <footer>
          <div className=" px-4 py-6 mx-auto sm:px-9 lg:px-8">
          
              <div className="grid grid-cols-2 gap-8 lg:col-span-2 sm:grid-cols-2 lg:grid-cols-4">
                <div>
                  <p className="font-medium text-white">Company</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <a href="/aboutus"> About us </a>
                    <a href="/clients"> Clients </a>
                    <a href="/careers"> Career </a>
                  </nav>
                </div>
                <div>
                  <p className="font-medium text-white">Services</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <a href="https://search.google.com/local/writereview?placeid=ChIJM0IEPqRRmg0RyLQAP_5varc">
                      {" "}
                      Google Map Review{" "}
                    </a>
                    <a href="/reviews"> Client Review </a>
                  </nav>
                </div>
                <div>
                  <p className="font-medium text-white">Helpful Links</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <a href="/contactus"> Contact </a>
                    <a href="/contactus"> FAQs </a>
                    <a href="https://api.whatsapp.com/send?phone=918143407758&text=Welcome%20to%20Cyberspacedigital">
                      {" "}
                      Live Chat{" "}
                    </a>
                  </nav>
                </div>
                <div>
                  <p className="font-medium text-white">Legal</p>
                  <nav className="flex flex-col mt-4 space-y-2 text-sm text-gray-500">
                    <Link to="/privacypolicy"> Privacy Policy </Link>
                    <Link to="/termsconditions">
                      <a> Terms &amp; Conditions </a>
                    </Link>

                    <Link to="/returnpolicy">
                      <a href=""> Cancellation & Refund Policy </a>
                    </Link>

                    <Link to="/disclaimer">
                      <a href=""> Disclaimer </a>
                    </Link>

                    <Link to="/shippingdelivery">
                      <a href=""> Shipping & Delivery </a>
                    </Link>
                  </nav>
                </div>
              </div>
            </div>

            <div class="h-px my-4 border-0 bg-gray-700" />
            <p
              class="cursor-default text-center text-xs leading-tight text-white mb-2"
            >
             All CopyRights Reserved to  {GlobalData.company.companyName}
              
            </p>

            <p
              class="cursor-default text-center text-xs leading-tight text-gray-500"
            >
              © 2024-<span> {currentYear} </span> <br />
              {GlobalData.company.companyWebsite}
              
            </p>
            <Link to="/">
                <img
                  src={footer_csd}
                  className=" h-[30px] justify-center mx-auto"
                  alt="footer_csd"
                  width="200" // Specify the width in pixels or another suitable unit
                  height="200" // Specify the height in pixels or another suitable unit
                />
              </Link>
              <p className="cursor-default text-center text-xs leading-tight text-gray-500">Web Development & Designer Community.</p>
        </footer>
        <section />

      
      </section>
    </main>
  );
};

export default Footer;
