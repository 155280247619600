import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/GlobalProvider"; // Assuming this is your context hook for user data
import { useNavigate } from "react-router-dom";
import { USER_CURRENCY } from "../../hooks/APIHooks";

function Dashboard() {
  const { user } = useAuth(); // Access user data
  const [currencyData, setCurrencyData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.user_id) {
      // Fetch user currency data from the API using the user_id
      fetch(USER_CURRENCY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user.user_id, // Send user_id to the API
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setCurrencyData(data.currency); // Set the data to state
          } else {
            console.error("Error fetching currency data", data.message);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [user]); // This runs when `user` is available or changes

  const handleLogout = () => {
    // Your logout logic
    navigate("/signin");
  };

  return (
    <section className="py-8">
      {/* Profile Section */}
      <section className="flex px-5 justify-center">
        <div className="max-w-lg w-full flex flex-col items-center bg-white rounded-2xl p-6 shadow-lg text-center">
          <h2 className="font-bold text-lg mt-3">Hello, {user.name}</h2>
          <p className="text-sm text-gray-500">userid: {user.user_id}</p>
          <p className="text-sm text-gray-500">{user.phonenumber}</p>
          <p className="text-sm text-gray-500">{user.emailaddress}</p>
          <span
            className={`text-xs px-2 py-1 rounded-full mt-2 ${
              user.is_active === 1 || user.is_active === "1"
                ? "bg-yellow-500 text-white"
                : "bg-red-500 text-white"
            }`}
          >
            {user.is_active === 1 || user.is_active === "1"
              ? "VERIFIED"
              : "NOT VERIFIED"}
          </span>
        </div>
      </section>

      {/* User Currency Data Section (Example) */}
      {currencyData && (
        <section className="bg-white mt-6 p-6 shadow-md rounded-lg">
          <h3 className="text-xl font-medium">Your Currency</h3>
          <p className="text-sm text-gray-500">
            Your current balance and information.
          </p>
          <div className="mt-4">
            <p className="font-semibold">
              Ants: {currencyData.digital_currency}
            </p>
          </div>
        </section>
      )}

      {/* Logout Section */}
      <button
        onClick={handleLogout}
        className="flex justify-center items-center space-x-2 w-full text-white py-2 rounded-md bg-red-600 hover:bg-red-700 transition duration-300"
      >
        <span>Logout</span>
      </button>
    </section>
  );
}

export default Dashboard;
