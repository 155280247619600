import React, { useState, useEffect } from "react";
import {
  AdvertisingCoverHomeImage,
  BrandingCoverHomeImage,
  DigiatalMarketingCoverHomeImage,
  GraphicDesigningCoverHomeImage,
  WebDevelopmentCoverHomeImage,
} from "../../assets/data/Imagedata";

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    {
      url: WebDevelopmentCoverHomeImage,
      button1: {
        text: "Shop Now",
        link: "/services/best-web-developement-company",
      },
    },
    {
      url: DigiatalMarketingCoverHomeImage,
      button1: {
        text: "Shop Now",
        link: "/services/best-digital-marketing-company",
      },
    },
    {
      url: GraphicDesigningCoverHomeImage,
      button1: {
        text: "Shop Now",
        link: "/services/best-graphic-designing-company",
      },
    },
    {
      url: BrandingCoverHomeImage,
      button1: {
        text: "Shop Now",
        link: "/services/best-brand-services-company",
      },
    },
    {
      url: AdvertisingCoverHomeImage,
      button1: {
        text: "Shop Now",
        link: "/services/best-advertising-company",
      },
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Autoplay functionality
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 2000); // Change slide every 2 seconds (adjust as needed)

    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, [currentIndex]);

  return (
    <div className="relative py-10 md:py-0">
      <div className="flex h-[20vh] md:h-[50vh] lg:h-[70vh]">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="w-full h-full absolute top-0 left-0"></div>
            <img
              src={image.url}
              alt={`Slide ${index + 1}`}
              className="w-full h-full object-cover rounded-b-2xl shadow-xl"
            />
            <div className="absolute bottom-5 left-0 right-0 flex items-center justify-center">
              <a
                href={image.button1.link}
                className="text-xs sm:text-sm transform rounded-full bg-black border border-slate-200 px-5 sm:px-12 py-2 font-medium text-white transition-colors mx-2"
              >
                {image.button1.text}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
