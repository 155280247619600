import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {
  Navbar,
  Footer,
  PrivacyPolicy,
  TermsnConditions,
  Disclaimer,
  ShippingandDelivery,
  ReturnPolicy,
  Error404,
  Home,
  Aboutus,ReportABug,
  Contactus,
  ProfileEdit,
  Register,
  Signin,
  Orders,OrderDetails,
} from "./routes/Routes";

import Cart from "./routes/Store/Cart";
import Checkout from "./routes/Store/Checkout";
import PaymentSuccess from "./routes/Store/Payment/PaymentSuccess";

import UserCurrencySummery from "./routes/user/UserCurrencySummery";

import ForgotPassword from "./routes/auth/ForgotPassword";
import { useAuth } from "./hooks/GlobalProvider";
import Dashboard from "./routes/user/Dashboard";

import LogoLoader from "./components/LogoLoader/LogoLoader";

// Add the new components for product catalogue
import Store from "./routes/Store/Store";
import SubCatalogueList from "./routes/Store/Products/SubCatalogueList";
import ProductList from "./routes/Store/Products/ProductList";
import ProductDetails from "./routes/Store/Products/ProductDetails";

const App = () => {
  const location = useLocation();
  const { user, loading } = useAuth();

  const [cart, setCart] = useState([]);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(savedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  if (loading) {
    return <LogoLoader />;
  }

  return (
    <>
      <Navbar />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/reportbug" element={<ReportABug />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsconditions" element={<TermsnConditions />} />
        <Route path="/returnpolicy" element={<ReturnPolicy />} />
        <Route path="/shippingdelivery" element={<ShippingandDelivery />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/user/dashboard"
          element={user ? <Dashboard /> : <Navigate to="/signin" />}
        />
        <Route path="/user/profileedit" element={<ProfileEdit />} />
        <Route
          path="/user/currency"
          element={user ? <UserCurrencySummery /> : <Navigate to="/signin" />}
        />

        <Route
          path="/Store/cart"
          element={user ? <Cart /> : <Navigate to="/signin" />}
        />
        <Route
          path="/Store/checkout"
          element={user ? <Checkout /> : <Navigate to="/signin" />}
        />
        <Route
          path="/Store/checkout/paymentsuccess"
          element={user ? <PaymentSuccess /> : <Navigate to="/signin" />}
        />
  {/* Routes for Dashboard */}
<Route
          path="/user/orders"
          element={user ? <Orders /> : <Navigate to="/signin" />}
        />
        <Route
          path="/user/orders/orderdetails"
          element={user ? <OrderDetails /> : <Navigate to="/signin" />}
        />

        {/* Routes for product catalogue */}
        <Route path="/Store" element={<Store />} />
        <Route
          path="/Store/sub-catalogue/:catalogueId"
          element={<SubCatalogueList />}
        />
        <Route
          path="/Store/sub-catalogue/products/:subCatalogueId"
          element={<ProductList />}
        />

        <Route
          path="/Store/sub-catalogue/products/product-details/:productId"
          element={<ProductDetails />}
        />

        <Route path="/*" element={<Error404 />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
