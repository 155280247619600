import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { FaRegRegistered } from "react-icons/fa";

function Notification() {
  // State to control visibility of the notification
  const [isVisible, setIsVisible] = useState(true);

  // Function to close the notification
  const closeNotification = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className="w-full z-[100] top-0 left-0 flex justify-center items-center">
          <div className="w-full max-w-full px-4 py-2 bg-primary shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="flex items-start justify-between">
              <div className="flex-shrink-0 my-auto">
                <FaRegRegistered size={30} />
              </div>
              <div className="ml-3 flex-1 leading-tight">
              <p className="sm:text-[16px] text-[14px] font-bold">Ragsee</p>
              <p className="sm:text-[14px] text-[12px]">South india's most trusted Ecommerce Platform. <a className="font-bold">#Ragsee #SouthIndiaPlatform</a></p>
            </div>
              <div>
                <button
                  type="button"
                  onClick={closeNotification} // Close the notification when clicked
                  className="inline-flex text-black hover:text-white"
                >
                  <IoIosClose size={30} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Notification;
