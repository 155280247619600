import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/GlobalProvider";
import { USER_CURRENCY } from "../../hooks/APIHooks";
import { Dashboard_Profile, referafriend } from "../../assets/data/Imagedata";
import { GlobalData } from "../../assets/data/GlobalData";
import { FaAngleDoubleRight, FaLifeRing, FaStar } from "react-icons/fa";
import {
  FcPaid,
  FcPackage,
  FcCancel,
  FcDownRight,
  FcShipped,
  FcPrivacy,
  FcRules,
  FcDataBackup,
  FcCustomerSupport,
  FcHighPriority,
  FcBusinessContact,
} from "react-icons/fc";
import { IoIosLogOut } from "react-icons/io";

function Dashboard() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [currencyData, setCurrencyData] = useState(null);

  useEffect(() => {
    if (user && user.user_id) {
      // Fetch user currency data from the API using the user_id
      fetch(USER_CURRENCY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user.user_id, // Send user_id to the API
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setCurrencyData(data.currency); // Set the data to state
          } else {
            console.error("Error fetching currency data", data.message);
          }
        })
        .catch((error) => console.error("Error:", error));
    }
  }, [user]); // This runs when `user` is available or changes

  const handleLogout = () => {
    signOut();
    navigate("/signin");
  };

  return (
    <section className=" py-8">
      {/* Profile Section */}
      <section className="flex px-5 justify-center">
        <div className="max-w-lg w-full flex flex-col items-center bg-white rounded-2xl p-6 shadow-lg text-center">
          <img
            src={Dashboard_Profile}
            alt="Dashboard Profile"
            className="w-20 h-20 rounded-full drop-shadow-md"
          />
          <h2 className="font-bold text-lg mt-3">Hello, {user.name} ({user.user_id})</h2>
          <p className="text-sm text-gray-500">{user.phonenumber}</p>
          <p className="text-sm text-gray-500">{user.emailaddress}</p>
          <span
            className={`text-xs px-2 py-1 rounded-full mt-2 ${
              user.is_active === 1 || user.is_active === "1"
                ? "bg-primary text-white"
                : "bg-red-500 text-white"
            }`}
          >
            {user.is_active === 1 || user.is_active === "1"
              ? "VERIFIED"
              : "NOT VERIFIED"}
          </span>
        </div>
      </section>

      {/* Orders Section */}
      <section className="bg-white px-5 shadow-md rounded-lg mt-6">
        <div className="text-center p-4">
          <h3 className="text-xl font-medium">Orders</h3>
          <p className="text-sm text-gray-500">
            Track Your Orders & Transactions
          </p>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 p-4">
          {[
            { icon: <FcPaid size={23} />, label: "Orders", url: "/user/orders" },
            {
              icon: <FcShipped size={23} />,
              label: "Processing",
              url: "/user/orders?filter=Processing",
            },
            {
              icon: <FcPackage size={23} />,
              label: "Delivered",
              url: "/user/orders?filter=Delivered",
            },
            { icon: <FcDownRight size={23} />, label: "Returns", url: "/user/orders?filter=Return" },
            {
              icon: <FcCancel size={23} />,
              label: "Cancelled",
              url: "/user/orders?filter=Cancelled",
            },
            {
              icon: <FaAngleDoubleRight size={23} />,
              label: "View More",
              url: "#",
            },
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <a
                href={item.url}
                className="text-gray-700 hover:text-yellow-500 transition"
              >
                {item.icon}
              </a>
              <p className="text-sm mt-1">{item.label}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Deal Breaker Section */}
      <section className="bg-white px-5 shadow-md rounded-lg mt-6">
        <div className="text-center p-4">
          <h3 className="text-xl font-medium">Deal Breaker</h3>
          <p className="text-sm text-gray-500">Track your Deals & Offers</p>
        </div>
        <div className="grid grid-cols-3 gap-4 p-4">
          {[
            {
              count: currencyData ? currencyData.digital_currency : 0,
              label: "Coins",  url: "/user/currency"
            },
            { count: 0, label: "Coupons", url: "#" },
            { count: 0, label: "Wishlist", url: "#" },
          ].map((item, index) => (
            <div key={index} className="flex flex-col items-center text-center ">
            <a
                href={item.url}
                className="text-gray-700 hover:text-yellow-500 transition"
              >
              <span className="text-xl font-bold text-gray-800 hover:text-yellow-500 transition">
                {item.count}
              </span>
              <p className="text-sm mt-1">{item.label}</p></a>
            </div>
          ))}
        </div>
      </section>

      {/* Refer Friend */}
      <section className="py-6 sm:h-[30vh] flex justify-center items-center">
        <div className="grid grid-cols-2 items-center gap-4 max-w-screen-lg w-full">
          {/* Text Content */}
          <div className="text-center md:text-left px-5">
            <h3 className="text-xl font-medium leading-tight">Refer & Earn</h3>
            <p className="text-sm text-gray-500 mb-4">
              Share with your Friends & Family to earn exciting offers.
            </p>

            <button
              onClick={() => {
                const shareData = {
                  title: "Refer & Earn",
                  text: "Join me on this amazing platform and earn exciting offers!",
                  url: window.location.href, // The current page URL
                };

                if (navigator.share) {
                  // Use the Web Share API if supported
                  navigator.share(shareData).catch((err) => {
                    console.error("Error sharing:", err);
                  });
                } else {
                  // Fallback: Copy URL to clipboard
                  navigator.clipboard
                    .writeText(shareData.url)
                    .then(() => {
                      alert("Link copied to clipboard!");
                    })
                    .catch((err) => {
                      console.error("Error copying URL:", err);
                    });
                }
              }}
              className="font-medium text-[16px] flex items-center mx-auto md:mx-0 px-5 py-3 md:py-4 md:px-8 rounded-xl capitalize bg-gradient-to-r from-orange-300 to-orange-500 hover:from-orange-500 hover:to-yellow-500 relative gap-2 transition duration-300 hover:scale-105 text-white shadow-glass"
            >
              Refer a Friend
              <span className="animate-ping absolute right-0 top-0 w-3 h-3 rounded-full bg-gradient-to-r from-orange-400 to-orange-700" />
            </button>
          </div>
          {/* Image */}
          <div className="flex justify-center md:justify-end">
            <img
              className="w-full sm:h-auto sm:w-[50vw] md:w-[25vw] lg:w-[10vw] rounded-l-full shadow-xl"
              src={referafriend}
              alt="Refer a Friend"
            />
          </div>
        </div>
      </section>

      {/* Personal and Transactions Section */}
      <section className="flex px-5 justify-center">
        <div className="max-w-lg w-full bg-white rounded-2xl shadow-lg p-6">
          <div className="space-y-6">
            <div className="border-t border-gray-300"></div>
            <div className="text-center">
              <h3 className="text-xl font-medium">Personal</h3>
              <p className="text-sm text-gray-500">Modify your profile</p>
              <Link to="#">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FaStar className="text-yellow-500 mr-2" size={20} />
                  My Reviews
                </button>
              </Link>
              <Link to="/user/profileedit">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FcBusinessContact className="mr-2" size={20} />
                  Edit Profile
                </button>
              </Link>
            </div>
            <div className="border-t border-gray-300"></div>
            <div className="text-center">
              <h3 className="text-xl font-medium">Help Center</h3>
              <p className="text-sm text-gray-500">Need more help?</p>
              <Link to="/privacypolicy">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FcPrivacy className="mr-2" size={20} />
                  Privacy Policy
                </button>
              </Link>
              <Link to="/termsconditions">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FcRules className="mr-2" size={20} />
                  Terms & Conditions
                </button>
              </Link>
              <Link to="/shippingdelivery">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FcShipped className="mr-2" size={20} />
                  Shipping & Delivery
                </button>
              </Link>
              <Link to="/returnpolicy">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FcDataBackup className="mr-2" size={20} />
                  Return Policy
                </button>
              </Link>
            </div>

            <div className="border-t border-gray-300"></div>
            <div className="grid grid-cols-2 gap-4 p-4">
              {[
                {
                  icon: <FcCustomerSupport size={30} />,
                  label: "Customer Support",
                  url: "/contactus",
                },

                {
                  icon: <FcHighPriority size={30} />,
                  label: "Report a Bug",
                  url: "/reportbug",
                },
              ].map((item, index) => (
                <div  key={index}>
                <a href={item.url}
                  className="flex flex-col items-center text-center shadow-xl p-2 rounded-md"
                >
                  <div className="text-gray-700 hover:scale-125">
                    {item.icon}
                  </div>
                  <p className="text-sm mt-1">{item.label}</p>
                </a></div>
              ))}
            </div>

            <div className="border-t border-gray-300"></div>
            <div className="text-center">
              <h3 className="text-xl font-medium">Know More</h3>
              <p className="text-sm text-gray-500">
                What is {GlobalData.company.companyName} ?{" "}
              </p>
              <Link to="/aboutus">
                <button className="flex items-center justify-center text-gray-700 mt-3 hover:text-yellow-500 transition">
                  <FaLifeRing className="mr-2" size={20} />
                  About {GlobalData.company.companyName}
                </button>
              </Link>
            </div>

            <button
              onClick={handleLogout}
              className="flex justify-center items-center space-x-2 w-full text-white py-2 rounded-md bg-red-600 hover:bg-red-700 transition duration-300"
            >
              <IoIosLogOut size={20} className="hover-yellow" />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Dashboard;
