//SubCatalogueList.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { Fetch_product_sub_catalogue } from '../../../hooks/APIHooks';

const SubCatalogueList = () => {
  const { catalogueId } = useParams(); // Get the catalogue ID from the URL
  const navigate = useNavigate(); // Initialize navigation
  const [subCatalogues, setSubCatalogues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch data from the API
    const fetchSubCatalogues = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await fetch(
          `${Fetch_product_sub_catalogue}?catalogue_id=${catalogueId}`
        );
        const data = await response.json();
        if (data.success) {
          setSubCatalogues(data.data);
        } else {
          throw new Error(data.error || "Failed to fetch sub-catalogues");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubCatalogues();
  }, [catalogueId]);

  const handleSubCatalogueClick = (subCatalogueId) => {
    // Navigate to the ProductList page with the sub_catalogue_id as a parameter
    navigate(`/Store/sub-catalogue/products/${subCatalogueId}`);
  };

  return (
    <div className="container mx-auto my-8 px-4">
      <h1 className="text-2xl font-bold mb-4">
        Sub-Catalogues for Catalogue ID: {catalogueId}
      </h1>
      

      {loading ? (
        <div className="text-center text-lg">Loading...</div>
      ) : error ? (
        <div className="text-center text-gray-500">
          Coming Soon
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {subCatalogues.map((subCatalogue) => (
            <div
              key={subCatalogue.sub_catalogue_id}
              className="p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
              onClick={() => handleSubCatalogueClick(subCatalogue.sub_catalogue_id)} // Add click handler
            >
              <h2 className="text-lg font-semibold mb-2 text-blue-600 hover:underline">
                {subCatalogue.sub_catalogue_name}
              </h2>
              <p className="text-gray-600 text-sm mb-4">
                {subCatalogue.sub_catalogue_id}
              </p>
              <p className="text-gray-600 text-sm mb-4">
                {subCatalogue.description}
              </p>
              <p className="text-gray-500 text-xs">
                Created At: {new Date(subCatalogue.created_at).toLocaleString()}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubCatalogueList;
