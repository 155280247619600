import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/GlobalProvider";
import { FaExpeditedssl, FaMoneyCheck, FaTeamspeak } from "react-icons/fa";
import {
  Payment_Gateway_Create_order,
  Payment_Gateway_Save_Order,
  Payment_Gateway_Save_payment,
} from "../../hooks/APIHooks";

function Checkout() {
  const { user } = useAuth();
  const [fullName, setFullName] = useState(user.name || "");
  const [userData, setUserData] = useState({
    emailaddress: "",
    phonenumber: "",
    address: "",
  });

  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [country, setCountry] = useState("India"); // Set default to "India"
  const [isTermsAccepted, setIsTermsAccepted] = useState(true); // State for terms checkbox

  useEffect(() => {
    if (user.name) {
      setUserData({
        emailaddress: user.emailaddress,
        phonenumber: user.phonenumber || "",
        address: user.address || "",
      });
    }
  }, [user]);

  const totalCartValue = cart.reduce((total, product) => {
    return total + product.price * product.quantity;
  }, 0);

  const shippingCharge = 150;
  const totalAmount = totalCartValue + shippingCharge;

  const handlePayment = async () => {
    // Prepare order details
    const orderDetails = {
      user_id: user.user_id,
      name: fullName,
      email: userData.emailaddress,
      phone: userData.phonenumber,
      address: handleShippingAddress(),
      cart: cart.map((item) => ({
        product_id: item.product_id,
        product_name: item.product_name,
        quantity: item.quantity,
        price: item.price,
        variant: item.variant,
        product_image: item.product_image,
      })),
      subtotal: totalCartValue,
      amount: totalAmount,
      shippingcharge: shippingCharge,
    };

    try {
      // Save order to database
      const saveOrderResponse = await fetch(Payment_Gateway_Save_Order, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(orderDetails),
      });

      const saveOrderResult = await saveOrderResponse.json();
      console.log(saveOrderResult); // Add this line to debug the response from save-order.php
      if (!saveOrderResult.success || !saveOrderResult.order_id) {
        alert("Failed to save order details.");
        return;
      }

      // Continue with Razorpay payment
      const razorpayOrder = {
        amount: totalAmount * 100, // Razorpay accepts amount in paisa
        currency: "INR",
        receipt: `order_rcptid_${saveOrderResult.order_id}`,
      };

      const razorpayResponse = await fetch(Payment_Gateway_Create_order, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(razorpayOrder),
      });

      const razorpayOrderData = await razorpayResponse.json();
      if (!razorpayOrderData.id) {
        alert("Error creating Razorpay order.");
        return;
      }

      const options = {
        key: "rzp_test_NVTkMcNuqnxHzc", // Razorpay Key ID test: rzp_test_NVTkMcNuqnxHzc || Live : rzp_live_K7PipKGoIY7p0n
        amount: razorpayOrder.amount,
        currency: "INR",
        name: "Ragsee",
        description: "Purchase Description",
        order_id: razorpayOrderData.id, // Razorpay Order ID
        handler: async function (response) {
          const paymentData = {
            order_id: saveOrderResult.order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          const paymentSaveResponse = await fetch(
            Payment_Gateway_Save_payment,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(paymentData),
            }
          );

          const paymentSaveResult = await paymentSaveResponse.json();
          if (paymentSaveResult.success) {
            window.location.href = "/Store/checkout/paymentsuccess";
          } else {
            alert(
              `Payment failed: ${paymentSaveResult.message || "Unknown error"}`
            );
          }
        },
        prefill: {
          name: fullName,
          email: userData.emailaddress,
          contact: userData.phonenumber,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  const handleShippingAddress = () => {
    return `${street}, ${city}, ${state}, ${pincode}, ${country}`;
  };

  const isFormValid = () => {
    // Check if all fields are filled and terms are accepted
    return (
      fullName &&
      userData.emailaddress &&
      userData.phonenumber &&
      street &&
      city &&
      state &&
      pincode &&
      country &&
      isTermsAccepted
    );
  };

  return (
    <section className="overflow-hidden px-10">
      <section className="mx-auto w-full grid grid-cols-1 gap-1 md:grid-cols-2 pt-10">
        <div>
          <p className="text-[28px] font-bold">Complete your order</p>
          <p className="mt-2 text-gray-600">
            Please do fill your details below
          </p>
        </div>
        <div>
          <p className="mt-2 flex items-center gap-2 text-gray-500">
            <FaExpeditedssl className="text-[20px] text-black" />
            SSL secured checkout
          </p>
          <p className="mt-2 flex items-center gap-2 text-gray-500">
            <FaTeamspeak className="text-[20px] text-black" />
            24/7 support available
          </p>
          <p className="mt-2 flex items-center gap-2 text-gray-500">
            <FaMoneyCheck className="text-[20px] text-black" />
            Payment option
          </p>
        </div>
      </section>

      <div className="py-16 mx-auto w-full grid grid-cols-1 gap-1 md:grid-cols-2">
        {/* Left side: Customer Details */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6">Your Details</h2>
          Your ID: {user.user_id}
          <div className="mb-6">
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Ram"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full px-2 rounded-md"
            />

            <input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              placeholder="Select Country"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full px-2 rounded-md"
            />

            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder="Select State"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full px-2 rounded-md"
            />

            <input
              type="text"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              placeholder="Street"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full"
            />
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="City"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full"
            />

            <input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              placeholder="Pincode"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full"
            />
            <input
              type="email"
              value={userData.emailaddress}
              onChange={(e) =>
                setUserData({ ...userData, emailaddress: e.target.value })
              }
              placeholder="Email"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full bg-gray-200 px-2 rounded-md"
              readOnly
            />
            <input
              type="text"
              value={userData.phonenumber}
              onChange={(e) =>
                setUserData({ ...userData, phonenumber: e.target.value })
              }
              placeholder="Phone Number"
              className="border-b border-gray-300 py-2 text-base text-black mt-4 w-full bg-gray-200 px-2 rounded-md"
              readOnly
            />
            <div className="mt-4">
              <input
                type="checkbox"
                checked={isTermsAccepted}
                onChange={() => setIsTermsAccepted(!isTermsAccepted)}
              />
              <span className="ml-2">I agree to the Terms & Conditions</span>
            </div>
          </div>
        </div>

        {/* Right side: Cart Summary */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6">Order Summary</h2>
          <div>
            <table className="min-w-full table-auto">
              <thead>
                <tr>
                  <th className="py-2 px-4 text-left">Product</th>
                  <th className="py-2 px-4 text-right">Price</th>
                </tr>
              </thead>
              <tbody>
                {cart.map((item) => (
                  <div
                    key={item.product_id}
                    className="flex justify-between items-center"
                  >
                    <div>
                      <img
                        src={item.product_image}
                        alt={item.product_name}
                        className="object-cover w-[100px] h-[100px]"
                      />
                      <span className="mt-1 tracking-tight text-xs text-gray-700">
                        {item.variant}
                      </span>
                    </div>

                    <span>{item.product_name}</span>

                    <span>
                      ₹{item.price} x {item.quantity}
                    </span>
                  </div>
                ))}
              </tbody>
            </table>
          </div>
          <p className="mt-4 text-gray-500">Sub Total: ₹{totalCartValue}</p>
          <p className="mt-4 text-gray-500">
            Shipping Charge: ₹{shippingCharge}
          </p>
          <p className="mt-4 text-gray-500">
            <strong>Total: ₹{totalAmount}</strong>
          </p>
          <button
            onClick={handlePayment}
            disabled={!isFormValid()}
            className={`bg-black text-white px-6 py-3 rounded-lg mt-4 w-full  ${
              isFormValid() ? "bg-black" : "bg-gray-300 cursor-not-allowed"
            }`}
          >
            Pay Now
          </button>
        </div>
      </div>
    </section>
  );
}

export default Checkout;
