import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/GlobalProvider"; // Context hook for user data
import { useNavigate, useLocation } from "react-router-dom";
import { Fetch_Orders_History } from "../../hooks/APIHooks";

function Orders() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState("All");

  // Extract query parameters from URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterParam = params.get("filter");
    setFilter(filterParam || "All");
  }, [location.search]);

  // Fetch user orders from the API
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(Fetch_Orders_History, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ user_id: user.user_id }),
        });
        const data = await response.json();
        if (data.success) {
          setOrders(data.orders);
          setFilteredOrders(data.orders);
        } else {
          setError(data.message || "Failed to fetch orders");
        }
      } catch {
        setError("An error occurred while fetching orders.");
      }
    };

    if (user?.user_id) fetchOrders();
  }, [user.user_id]);

  // Handle filter change and update filtered orders
  const handleFilterChange = (status) => {
    setFilter(status);
    navigate(`?filter=${status}`);
    setFilteredOrders(
      status === "All"
        ? orders
        : orders.filter((order) => order.delivery_status === status)
    );
  };

  // Navigate to order details page
  const handleOrderClick = (order) => {
    navigate("/user/orders/orderdetails", { state: { order } });
  };

  return (
    <section>
      {/* Filter Section */}
      <section className="px-5">
      <h3 className="font-bold text-xl text-center py-5 text-primary">Your Orders</h3>
        <div
          className="flex space-x-2 text-[14px] overflow-x-auto scrollbar-hide"
          style={{ scrollbarWidth: "none" }}
        >
          {["All", "Processing", "Delivered", "Return", "Cancelled"].map(
            (status) => (
              <button
                key={status}
                onClick={() => handleFilterChange(status)}
                className={`px-4 py-2 rounded-lg ${
                  filter === status ? "bg-primary text-white" : "bg-gray-200"
                }`}
              >
                {status}
              </button>
            )
          )}
        </div>
      </section>

      {/* Orders Section */}
      <section className="mt-4 px-5">
        {error && <p className="text-red-500">{error}</p>}

        <div className="bg-white">
          {filteredOrders.length > 0 ? (
            <ul>
              {filteredOrders.map((order) => (
                <li
                  key={order.order_id}
                  className="border-b py-4 cursor-pointer"
                  onClick={() => handleOrderClick(order)}
                >
                  <ul className="ml-4">
                    {JSON.parse(order.cart_items).map((item, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-between border-b pb-2"
                      >
                        {/* Left Section: Product Details */}
                        <div className="space-y-2">
                          <div className="font-semibold text-[14px]">
                            {item.variant}
                          </div>
                          <div className="text-sm">Qty: {item.quantity}</div>
                          <div className="grid grid-cols-2 text-center">
                          <div className="text-[12px]">
  Payment Status
  <br />
  <p
    className={
      order.payment_status === "Pending"
        ? "text-black font-bold"
        : order.payment_status === "Completed"
        ? "text-green-600 font-bold"
        : order.payment_status === "Failed"
        ? "text-red-500 font-bold"
        : "text-gray-500" // Default color for any unexpected status
    } 
  >
    {order.payment_status}
  </p>
</div>

                            <div className="text-[12px]">
                              Shipping Status
                              <br />
                              <p>{order.delivery_status}</p>
                            </div>
                          </div>

                          <div className="text-[10px] font-bold">
                            ORDER CREATED : {order.created_at}
                          </div>
                        </div>
                        {/* Right Section: Product Image */}
                        <div className="w-32 h-32">
                          <img
                            src={item.product_image}
                            alt={item.product_name}
                            className="w-full h-full object-cover object-top rounded-lg shadow-sm"
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No orders found for this filter.</p>
          )}
        </div>
      </section>
    </section>
  );
}

export default Orders;
